<template>
  <div class='container'>
    <h2>Add A VehicleThreeSixtyImages</h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <div class='row'>
      <div class=' col-md-4'><label class='' for='ModelId'>ModelId</label><input class=' form-control' id='ModelId'
          name='ModelId' v-model='newVehicleThreeSixtyImages.ModelId' placeholder='ModelId' tabindex='0' type='text'
          value=''>
        <div class=''><small></small></div>
      </div>
      <div class=' col-md-4'><label class='' for='ColourName'>ColourName</label><input class=' form-control' id='ColourName'
          name='ColourName' v-model='newVehicleThreeSixtyImages.ColourName' placeholder='ColourName' tabindex='0' type='text'
          value=''>
        <div class=''><small></small></div>
      </div>
      <div class='col-md-4'>
        <label class for='Image'>Thumbnail Image</label>
        <div v-if='!editingImage'>
          <img v-bind:src='getImageSource(newVehicleThreeSixtyImages)' class='previewImage'>
          <button @click='editImage()' type='button' class='btn btn-success'>Update Image</button>
        </div>
        <div v-if='editingImage'>
          <button @click='updateImage()' type='button' class='btn btn-success'>Update</button>
          <button @click='editImage()' type='button' class='btn btn-warning'>Cancel</button>
          <FileUploader @ImageDataChanged='updateImageData' @ImageURLDataChanged='updateImageUrl' />
        </div>
      </div>
      <div class=' col-md-4'><label class='' for='ImageMeta'>ImageMeta</label><input class=' form-control' id='ImageMeta'
          name='ImageMeta' v-model='newVehicleThreeSixtyImages.ImageMeta' placeholder='ImageMeta' tabindex='0' type='text'
          value=''>
        <div class=''><small></small></div>
      </div>
      <div class=' col-md-4'><label class='' for='LastUpdated'>LastUpdated</label><input class=' form-control' id='LastUpdated'
          name='LastUpdated' v-model='newVehicleThreeSixtyImages.LastUpdated' placeholder='LastUpdated' tabindex='0'
          type='text' value=''>
        <div class=''><small></small></div>
      </div>
      <br />
    </div>
    <div class='row'>
      <div class='col-md-4'>
        <label class for='Image'>Upload 360 view Files</label>
        <div v-if="showMultiFileUploader()">
          <MultiFileUploader :ModelId='newVehicleThreeSixtyImages.ModelId' :Colour='newVehicleThreeSixtyImages.ColourName' />
        </div>
      </div>
    </div>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br />
    <br />
  </div>

</template>
<script>
  import _ from 'lodash';
  import FileUploader from '@/components/custom/FileUploader.vue';
  import MultiFileUploader from '@/components/custom/MultiFileUploader';
  import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
  const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
  };
  miniToastr.init({
    types: toastTypes
  });
  export default {
    name: 'addVehicleThreeSixtyImages',
    created: function () {},
    data() {
      return {
        newVehicleThreeSixtyImages: {},
        editingImage: false,
        uploadedImageData: {},
        uploadedImageUrl: null,
      }
    },
    components: {
      FileUploader,
      MultiFileUploader
    },

    methods: {

      showMultiFileUploader() {
        if (this.newVehicleThreeSixtyImages.ModelId && this.newVehicleThreeSixtyImages.ColourName) {
          return true;
        }
        return false;
      },
      toggleView: function () {
        this.$emit('closeAddVehicleThreeSixtyImages')
      },
      updateImageData: function (value) {
        this.uploadedImageData = value;
      },
      updateImageUrl: function (value) {
        this.uploadedImageUrl = value;
      },
      editImage: function () {
        this.editingImage = !this.editingImage;
      },
      updateImage: function () {
        this.editingImage = !this.editingImage;
      },
      getImageSource(data) {
        if (this.uploadedImageUrl) {
          return this.uploadedImageUrl;
        }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
          if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
          } else {
            return data.Image;
          }
        }
        return '';
      },
      isNull: function (obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
          return true;
        }
        return false;
      },
      save: function () {
        var data = JSON.parse(JSON.stringify(this.newVehicleThreeSixtyImages));

        data['Image'] = this.$data.uploadedImageData;

        var payload = {
          data: this.newVehicleThreeSixtyImages,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('addVehicleThreeSixtyImagesSuccess')
          },
          error: error => {
            miniToastr['error'](error, 'Error', 1000, null)
          }
        }
        this.$store.dispatch('addVehicleThreeSixtyImages', payload);
      }
    },
    watch: {
      newVehicleThreeSixtyImages(val) {
        this.$store.state.currentThreeSixtyItem = val
      }
    },
  }

</script>
<style scoped>
  .previewImage {
    max-height: 100px;
  }

</style>
