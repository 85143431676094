<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addVehicleThreeSixtyImagesModal' hide-footer>
        <addVehicleThreeSixtyImages 
        
        @closeAddVehicleThreeSixtyImages='toggleAddVehicleThreeSixtyImages()' @addVehicleThreeSixtyImagesSuccess='addVehicleThreeSixtyImagesSuccess()'></addVehicleThreeSixtyImages>
      </b-modal>
      <b-modal ref='editVehicleThreeSixtyImagesModal' hide-footer>
        <editVehicleThreeSixtyImages
          :editingVehicleThreeSixtyImages='current360Item'
          @closeEditVehicleThreeSixtyImages='toggleeditVehicleThreeSixtyImages()'
          @editVehicleThreeSixtyImagesSuccess='editVehicleThreeSixtyImagesSuccess()'
        ></editVehicleThreeSixtyImages>
      </b-modal>
      <div class='row'>
        <h2>360 View</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddVehicleThreeSixtyImages()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addVehicleThreeSixtyImages  from '../../components/custom/addVehicleThreeSixtyImages';
import editVehicleThreeSixtyImages from '../../components/custom/editVehicleThreeSixtyImages';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Model Id',
                field: 'ModelId',
                sort: 'asc',
              },
              {
                label: 'Colour Name',
                field: 'ColourName',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'Image Meta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'Last Updated',
                field: 'LastUpdated',
                sort: 'asc',
              }
      ],
      items: [],
      addVehicleThreeSixtyImagesOpen: false,
      editVehicleThreeSixtyImagesOpen: false,
      current360Item: {},
      output: []
    };
  },
  components: {
    addVehicleThreeSixtyImages,
    editVehicleThreeSixtyImages,
    Datatable,
  },
  created: function() {
    this.getVehicleThreeSixtyImages();
    this.addVehicleThreeSixtyImagesOpen = false;
    this.editVehicleThreeSixtyImagesOpen = false;
  },
  methods: {
    getImageSource(data) {



         var config = {
                      baseURL: baseUrl,//'http://localhost:57626/', //'http://localhost:57626/',
                      headers: {
                      //'Content-Type': 'multipart/form-data'
                      'Content-Type': 'application/json'
                      },
                      onUploadProgress: function(progressEvent){
                      //this.total=progressEvent.loaded * 100;
                      var percentCompleted = Math.round((progressEvent.loaded * 100)/ progressEvent.total);
                      total=progressEvent.loaded * 100;
                      },
          
                     }
        var self1 = this;

        var formData = new FormData();
        //var imagefile = document.querySelector('#fileUploader');
        //formData.append("image", imagefile.files[0]);
        //axios.get('import360?path=elantra&colour=white', formData, config)
        return axios.get(`Import360?path=${data.ModelId}&colour=${data.Colour}`,config)
            .then(response => {
              // returning the data here allows the caller to get it through another .then(...)
              console.log(response.data);
              response.data.forEach(function(item,index){

                console.log(item);
                self1.output.push(item) ;
                })
              //self1.output =  JSON.parse(   response.data              );
                
              return response.data;
              }).data;
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    getVehicleThreeSixtyImages() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getVehicleThreeSixtyImages', payload);
    },
    editItem(dataItem) {
      this.toggleeditVehicleThreeSixtyImages();
      console.log(dataItem);
      this.$store.state.currentThreeSixtyItem  = dataItem;
      this.current360Item = dataItem;
    },
    toggleAddVehicleThreeSixtyImages() {
      if(this.addVehicleThreeSixtyImagesOpen)
      {
        this.$refs.addVehicleThreeSixtyImagesModal.hide()
      }
      else{
        this.$refs.addVehicleThreeSixtyImagesModal.show()
      }
      this.addVehicleThreeSixtyImagesOpen = !this.addVehicleThreeSixtyImagesOpen;
    },
    addVehicleThreeSixtyImagesSuccess() {
      this.toggleAddVehicleThreeSixtyImages();
      miniToastr['success']('VehicleThreeSixtyImages Added', 'Success', 1000, null);
      this.getVehicleThreeSixtyImages();
    },
    toggleeditVehicleThreeSixtyImages() {
      if(this.editVehicleThreeSixtyImagesOpen)
      {
        this.$refs.editVehicleThreeSixtyImagesModal.hide()
      }
      else{
        this.$refs.editVehicleThreeSixtyImagesModal.show()
      }
      this.editVehicleThreeSixtyImagesOpen = !this.editVehicleThreeSixtyImagesOpen;
    },
    editVehicleThreeSixtyImagesSuccess() {
      
      this.toggleeditVehicleThreeSixtyImages();
      miniToastr['success']('getVehicleThreeSixtyImages Edited', 'Success', 1000, null);
      this.getVehicleThreeSixtyImages();
    }
  }
};
</script>
<style scoped>
.dataItemThumbnail {
  height: 50px;
}
</style>
