<template>
  <div class='container'>
    <h2>Edit VehicleThreeSixtyImages</h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>

    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>

    <button @click='deleteVehicleThreeSixtyImages()' type='button' class='btn btn-danger'>Delete</button>

    <div class='row'>
      <div class='col-md-4'>
        <label class for='ModelId'>ModelId</label>
        <input class='form-control' id='ModelId' name='ModelId' v-model='editingVehicleThreeSixtyImages.ModelId'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class='col-md-4'>
        <label class for='ColourName'>ColourName</label>
        <input class='form-control' id='ColourName' name='ColourName' v-model='editingVehicleThreeSixtyImages.ColourName'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class='col-md-4'>
        <label class for='Image'>Colour Thumbnail</label>
        <div v-if='!editingImage'>
          <img v-bind:src='getImageSourceThumb(editingVehicleThreeSixtyImages)' class='previewImage'>
          <button @click='editImage()' type='button' class='btn btn-success'>Update Image</button>
        </div>
        <div v-if='editingImage'>
          <button @click='updateImage()' type='button' class='btn btn-success'>Update</button>
          <button @click='editImage()' type='button' class='btn btn-warning'>Cancel</button>
          <FileUploader @ImageDataChanged='updateImageData' @ImageURLDataChanged='updateImageUrl' />
        </div>
      </div>
      <div class='col-md-2'>
        <label class for='ImageMeta'>ImageMeta</label>
        <input class='form-control' id='ImageMeta' name='ImageMeta' v-model='editingVehicleThreeSixtyImages.ImageMeta'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class='col-md-4'>
        <label class for='LastUpdated'>LastUpdated</label>
        <input class='form-control' id='LastUpdated' name='LastUpdated' v-model='editingVehicleThreeSixtyImages.LastUpdated'
          placeholder=' ' tabindex='0' type='text'>

      </div>

    </div>
    <div class='row'>
      <div class='col-md-4'>
        <label class for='Image'>Upload 360 view Files</label>
        <div v-if="showMultiFileUploader()">
          <MultiFileUploader :ModelId='editingVehicleThreeSixtyImages.ModelId' :Colour='editingVehicleThreeSixtyImages.ColourName' />
        </div>
      </div>
    </div>
    <br />
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br />
    <br />
  </div>

</template>
<script>
  import _ from 'lodash';
  import axios from 'axios';
  import FileUploader from '@/components/custom/FileUploader';
  import MultiFileUploader from '@/components/custom/MultiFileUploader';
  import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
  import {
    EventBus
  } from '@/shared/EventBus'


  const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
  };
  miniToastr.init({
    types: toastTypes
  });

  export default {
    name: 'EditVehicleThreeSixtyImages',

    data() {
      return {
        imageBytes: {},
        selectedFile: {},
        originalData: {},
        modifiedData: {},
        uploadedImageData: {},
        uploadedImageUrl: null,
        editingImage: false,
        output: []
      };
    },
    components: {
      MultiFileUploader,
      FileUploader
    },
    created() {
      this.originalData = JSON.parse(JSON.stringify(this.editingVehicleThreeSixtyImages));
      this.ImageBytes = this.editingVehicleThreeSixtyImages.Image;
    },
    mounted() {

    },

    props: ['editingVehicleThreeSixtyImages'],

    methods: {
      getCurrentData() {
        var data = this.$store.state.currentThreeSixtyItem;
        console.log(data);
        var config = {
          baseURL: this.$store.state.baseUrl,
          headers: {
            'Content-Type': 'application/json'
          },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            total = progressEvent.loaded * 100;
          },

        }
        var self1 = this;

        var formData = new FormData();
        axios.get(`Import360?path=${data.ModelId}&colour=${data.ColourName}`, config)
          .then(response => {
            console.log(response.data);
            response.data.forEach(function (item, index) {
              console.log(item);
              self1.output.push(item);
            })
            return response;
          });
        return self1.output;
      },
      showMultiFileUploader() {
        if (this.$store.state.currentThreeSixtyItem && this.$store.state.currentThreeSixtyItem.ModelId) {
          return true;
        }
        return false;
      },
      toggleView: function () {
        this.$emit("closeEditVehicleThreeSixtyImages");
      },
      save: function () {
        var data = JSON.parse(JSON.stringify(this.editingVehicleThreeSixtyImages));
        // data['Image'] = this.$data.uploadedImageData;
        var payload = {
          data: data,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('editVehicleThreeSixtyImagesSuccess')
          },
          error: error => {
            miniToastr['error'](error, 'Error', 1000, null)
          }
        }
        this.$store.dispatch('editVehicleThreeSixtyImages', payload);
      },
      updateImageData: function (value) {
        this.uploadedImageData = value;
      },
      updateImageUrl: function (value) {
        this.uploadedImageUrl = value;
      },
      editImage: function () {
        this.editingImage = !this.editingImage;
      },
      updateImage: function () {
        this.editingImage = !this.editingImage;
      },
      getImageSourceThumb(data) {
        if (this.uploadedImageUrl) {
          return this.uploadedImageUrl;
        }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
          if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
          } else {
            return data.Image;
          }
        }
        return '';
      },
      getImageSource(data) {
        console.log(data);
        var config = {
          baseURL: this.$store.state.baseUrl,
          headers: {
            'Content-Type': 'application/json'
          },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            total = progressEvent.loaded * 100;
          },

        }
        var self1 = this;
        var formData = new FormData();
        axios.get(`/Import360?path=${data.ModelId}&colour=${data.ColourName}`, config)
          .then(response => {
            console.log(response.data);
            response.data.forEach(function (item, index) {
              console.log(item);
              self1.output.push(item);
            })
            return response;
          });
        return self1.output;
      },
      isNull: function (obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
          return true;
        }
        return false;
      },
      deleteVehicleThreeSixtyImages: function () {
        var payload = {
          data: this.editingVehicleThreeSixtyImages,
          success: response => {
            this.$emit('editVehicleThreeSixtyImagesSuccess')
          },
          error: error => {
            miniToastr['error'](error, 'Error', 1000, null)
          }
        }
        this.$store.dispatch('deleteVehicleThreeSixtyImages', payload);
      }
    },
    computed: {
      currentThreeSixtyItem() {
        this.$store.state.currentThreeSixtyItem
      }
    },
    watch: {
      currentThreeSixtyItem(val) {
        this.getCurrentData();
      }
    },
    beforeMount() {}
  };

</script>
<style scoped>
  .previewImage {
    max-height: 100px;
  }

  .imgHolder {

    position: relative;
  }

  .imgHolder span {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .previewImage {
    max-height: 100px;
  }

</style>
